/** bu prefix for BoostUp */

/**
css variables, can be use everywhere even in css files or inline style property
```
.example {
  color: var(--bu-primary-500)
}
```
*/
:root {
  /** Colors as outlined in design system
Grays */

  /** Fonts **/
  --bu-font-regular: 'InterRegular', sans-serif;
  --bu-font-medium: 'InterMedium', sans-serif;
  --bu-font-semi-bold: 'InterSemiBold', sans-serif;

  /**shadows**/
  --bu-shadow-basic: rgba(34, 36, 38, 0.15) 0px 1px 2px 0px;
  --bu-shadow-deep: rgba(0, 0, 0, 0.2) 0 0 6px 0;

  /** Limegreen **/
  --bu--limegreen-100: #f1feb3;
  --bu--limegreen-500: #c8f203;
  --bu--limegreen-600: #dafd35;

  --bu--table-header: #eef3fc;
  --bu--table-header-sorting: #e5edfa;

  --bu-table-header-border-color: var(--bu-gray-400);
  --bu-table-border-color: #e5e9eb;

  --bu-table-user-icon-level-3: #8fcdff;
  --bu-table-user-icon-level-2: #70a7ff;
  --bu-table-user-icon-level-1: var(--bu-primary-500);
  --bu-table-user-icon-level-0: var(--bu-primary-700);

  --bu-open-filters-changed-border: #80b0ff;
  --bu-open-filters-changed-background: #e5efff;

  --bu-color-left-panel-logo-part-1: var(--bu-white);
  --bu-color-left-panel-logo-part-2: #ccdfff;

  /** Grays */
  --bu-gray-100: #f6f7f8;
  --bu-gray-200: #f7faff;
  --bu-gray-300: #eff3f8;
  --bu-gray-400: #d2d6d9;
  --bu-gray-500: #b1b6b8;
  --bu-gray-600: #92989c;
  --bu-gray-700: #626669;
  --bu-gray-800: #43494d;
  --bu-gray-900: #222525;
  --bu-white: #ffffff;
  /** Blues */
  --bu-primary-100: #f4f8ff;
  --bu-primary-200: #ebf1ff;
  --bu-primary-500: #3884ff;
  --bu-primary-700: #1453bc;
  --bu-primary-800: #0f3f8f;
  --bu-primary-900: #0f2838;
  /** Greens */
  --bu-green-200: #edfce9;
  --bu-green-300: #c0f4af;
  --bu-green-500: #00a674;
  --bu-green-700: #007d5c;
  /** Limes */
  --bu-lime-300: #f0fdce;
  --bu-lime-500: #9ec62f;
  --bu-lime-700: #7c9830;
  /** Yellows */
  --bu-yellow-200: #fff7d4;
  --bu-yellow-300: #fff0ad;
  --bu-yellow-500: #cca300;
  --bu-yellow-700: #8a5000;
  /** Oranges */
  --bu-orange-200: #fff8e5;
  --bu-orange-300: #ffe9b5;
  --bu-orange-500: #ef8a25;
  --bu-orange-700: #d6730f;
  /** Reds */
  --bu-red-100: #ffebf0;
  --bu-red-300: #ffcedc;
  --bu-red-400: #f05a5a;
  --bu-red-600: #be2a2a;
  --bu-red-700: #e24444;
  /** Purples */
  --bu-purple-300: #f4e9fc;
  --bu-purple-500: #a34ce5;
  --bu-purple-700: #6b3098;
  /** Indigos */
  --bu-indigo-300: #cad2f7;
  --bu-indigo-500: #475fde;
  --bu-indigo-700: #1d34a5;

  --bu-warning-400: #ff8754;

  --bu-color-positive: var(--bu-green-500);
  --bu-color-negative: var(--bu-red-400);
  --bu-color-caution: var(--bu-orange-500);

  --bu-read-only-primary: #f5f5f7;
  --bu-read-only-secondary: #afb7ba;
  --bu-read-only-border-color: #d2d6d9;
  --bu-read-only-placeholder-color: #afb7ba;

  --bu-text-primary: var(--bu-gray-900);
  --bu-text-seconday: var(--bu-gray-700);
  --bu-text-disabled: var(--bu-gray-600);
  --bu-text-white: var(--bu-white);
  --bu-text-gray: #6b7275;

  --bu-control-height-small: 30px;
  --bu-control-height-regular: 32px;
  --bu-control-height-big: 34px;

  --bu-control-border-radius: 4px; /* small */
  --bu-control-border-radius-medium: 12px;
  --bu-control-border-radius-large: 36px;
  --bu-control-border-radius-extra-large: 48px;

  --bu-control-gap-small: 4px;
  --bu-control-gap-medium: 8px;
  --bu-control-gap-large: 12px;
  --bu-control-gap-extra-large: 16px;
  --bu-control-gap-xx-large: 24px;
  --bu-control-gap-xxx-large: 32px;

  --bu-font-size-small: 0.9em;
  --bu-font-size-big: 1.1em;

  --bu-color-call-video-control: #f0f2f4;
  --bu-color-call-video-time: #ebeced;
  --bu-color-call-light-gray: var(--bu-text-gray);
  --bu-color-call-positive-insight-border: #00e5a1;
  --bu-color-call-negative-insight-border: #f59393;
  --bu-color-call-topics-insight-background: #f2f2f2;
  --bu-colol-call-timeline-highlight-section: #92989c40;

  --bu-color-call-summary-sentiment-negative-background: var(--bu-red-100);
  --bu-color-call-summary-sentiment-neutral-background: #fef7f1;
  --bu-color-call-summary-sentiment-positive-background: var(--bu-green-200);
  --bu-color-call-summary-sentiment-na-background: var(--bu-gray-300);

  --bu-color-meeting: #ff8754;
  --bu-color-buying_team_email: #3384ff;
  --bu-color-selling_team_email: #32d989;
  --bu-color-next_steps: #a34ce5;
  --bu-color-crm_activity: #ff588b;

  --bu-color-chart-boostup_projection: #0762ec;
  --bu-color-chart-company_forecast: #a669ce;
  --bu-color-chart-expected_booked: #92ad42;
  --bu-color-chart-in_play: #e19ec6;

  /**
    Trends bar colors
  */
  --bu-color-prior-forecast-bar: #8995ff;
  --bu-color-prior-forecast-bar-border: #5768ff;
  --bu-color-current-forecast-bar: var(--bu-primary-500);
  --bu-color-current-forecast-bar-border: var(--bu-primary-700);
  --bu-color-positive-bar: #00c288;
  --bu-color-positive-bar-border: var(--bu-green-500);
  --bu-color-negative-bar: #f27373;
  --bu-color-negative-bar-border: var(--bu-red-400);
  --bu-color-caution-chart: #f1963c;

  /** Constants colors
  (Common metrics like Forecast Target, Pipeline Creation Target,
  Pipeline Coverage Target, Booked, Lost, Total Pipe, Commit,
  Best Case, Pipeline and Pipeline Coverage) */

  --bu-color-constant-forecast-target: #f1963c;
  --bu-color-constant-pipeline-creation-target: #9537e7;
  --bu-color-constant-pipeline-coverage-target: #0488b3;
  --bu-color-constant-booked: #00d696;
  --bu-color-constant-lost: #f27373;
  --bu-color-constant-total-pipe: #5fcaf1;
  --bu-color-constant-commit: #5281e0;
  --bu-color-constant-best-case: #05a5ea;
  --bu-color-constant-pipeline: #0ce4e4;
  --bu-color-constant-pipeline-coverage: #8098c6;
  --bu-color-constant-omitted: #d2c128;
  --bu-color-constant-closed-won: #00d66b;
  --bu-color-constant-closed-lost: #f05c5c;

  /**
  Dynamic Bars
  To be used for bar/column/pie charts applying colors randomly as it's required
  */

  --bu-color-dynamic-bar-yellow: #f0d042;
  --bu-color-dynamic-bar-aqua: #1dcdc4;
  --bu-color-dynamic-bar-lime: #b5e13a;
  --bu-color-dynamic-bar-portage: #8995ff;
  --bu-color-dynamic-bar-blue: #5297ff;
  --bu-color-dynamic-bar-pink: #f183b8;
  --bu-color-dynamic-bar-violet: #8e6af5;
  --bu-color-dynamic-bar-orange: #f1ae55;
  --bu-color-dynamic-bar-desaturated-red: #c9ac13;
  --bu-color-dynamic-bar-fresh-green: #0bf49f;
  --bu-color-dynamic-bar-green: #00c288;
  --bu-color-dynamic-bar-purple: #9853e9;
  --bu-color-dynamic-bar-strong-pink: #ec4899;
  --bu-color-dynamic-bar-sky-blue: #0cb1e5;
  --bu-color-dynamic-bar-cyan: #469ca0;

  /**
  Dynamic Lines
  To be used for line charts applying colors randomly as it's required
  */

  --bu-color-dynamic-line-dark-green: #00996b;
  --bu-color-dynamic-line-green: #00b27d;
  --bu-color-dynamic-line-dark-lime: #91b002;
  --bu-color-dynamic-line-lime: #afd403;
  --bu-color-dynamic-line-dark-aqua: #118ea7;
  --bu-color-dynamic-line-aqua: #14aecc;
  --bu-color-dynamic-line-mint: #1cba7b;
  --bu-color-dynamic-line-dark-blue: #1d80b9;
  --bu-color-dynamic-line-blue: #2c9dde;
  --bu-color-dynamic-line-dark-indigo: #1453bc;
  --bu-color-dynamic-line-indigo: #3884ff;
  --bu-color-dynamic-line-dark-purple: #7d2d9f;
  --bu-color-dynamic-line-purple: #9d38c7;
  --bu-color-dynamic-line-dark-pink: #e24444;
  --bu-color-dynamic-line-pink: #f05a5a;
  --bu-color-dynamic-line-dark-orange: #df7416;
  --bu-color-dynamic-line-dark-yellow: #c2a800;
  --bu-color-dynamic-line-yellow: #e0c200;
  --bu-color-dynamic-line-dark-brown: #74139f;
  --bu-color-dynamic-line-brown: #9218c8;

  --bu-clickable-cell: var(--bu-primary-700);
  --bu-clickable-cell__hover: var(--bu-primary-500);

  --bu-control-disable-opacity: 0.5;

  --bu-color-chart-additional_fields_1: #ac5fc1;
  --bu-color-chart-additional_fields_2: #64cae6;
  --bu-color-chart-additional_fields_3: #eb85a3;
  --bu-color-chart-additional_fields_4: #61e37d;
  --bu-color-chart-additional_fields_5: #7d84aa;
  --bu-color-chart-additional_fields_6: #c28ed7;
  --bu-color-chart-additional_fields_7: #cdad71;

  --bu-color-datadog-purple: #5c2d90;
  --bu-color-fieldset-background: #fbfdfe;

  /**
  Default chart color
  To be used for Default chart ie: (funnel, bar chart)
  */

  --bu-color-default-chart: #3884ffe6;
  --bu-color-default-chart-hover: #3884ff;

  --bu-color-default-chart-red: #f05a5ae6;
  --bu-color-default-chart-red-hover: #f05a5a;
}

/** Inter Fonts **/
@font-face {
  font-family: 'InterMedium';
  src: url('../fonts/Inter/Inter-Medium.ttf');
}

@font-face {
  font-family: 'InterRegular';
  src: url('../fonts/Inter/Inter-Regular.ttf');
}

@font-face {
  font-family: 'InterSemiBold';
  src: url('../fonts/Inter/Inter-SemiBold.ttf');
}

.bu-font-display {
  font-family: var(--bu-font-regular);
  font-size: 24px;
}

.bu-font-section-title {
  font-family: var(--bu-font-medium);
  font-size: 18px;
  font-weight: 600;
}

.bu-font-heading {
  font-family: var(--bu-font-semi-bold);
  font-size: 16px;
}

.bu-font-sub-heading {
  font-family: var(--bu-font-semi-bold);
  font-size: 14px;
  text-transform: uppercase;
}

.bu-font-default {
  font-family: var(--bu-font-regular);
  font-size: 14px;
}

.bu-font-caption {
  font-family: var(--bu-font-regular);
  font-size: 12px;
}

.bu-font-subtitle {
  font-family: var(--bu-font-medium);
}

/**
Icons group
@example
```
<div class="bu-icons-group">
  <div class="bu-icon-boostup_logo_part_1" style="color: skyblue;"></div>
  <div class="bu-icon-boostup_logo_part_2" style="color: white;"></div>
</div>
```
 */
.bu-icon {
  font-family: BoostUpIcons;
  vertical-align: middle;
  text-align: center;
  backface-visibility: hidden;
}

.bu-icons-group {
  font-family: BoostUpIcons;
  position: relative;
  display: inline-block;
  line-height: 1;
}

.bu-icons-group > i[class^='bu-icon-'],
.bu-icons-group > i[class*=' bu-icon-'] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin: 0;
}

.bu-icons-group > i[class^='bu-icon-']:first-child,
.bu-icons-group > i[class*=' bu-icon-']:first-child {
  position: static;
  width: auto;
  height: auto;
  vertical-align: top;
  transform: none;
}

/** Predefined logo colors */
.bu-icons-group > .bu-icon-boostup_logo_part_1 {
  color: var(--bu-primary-500);
}

.bu-icons-group > .bu-icon-boostup_logo_part_2 {
  color: var(--bu-primary-700);
}

.bu-icons-group > .bu-icon-boostup_part_1 {
  color: var(--bu-primary-500);
}

.bu-icons-group > .bu-icon-boostup_part_2 {
  color: var(--bu-primary-700);
}

/** Predefined icon colors */
.bu-icon-timeline_buying_team_email {
  color: var(--bu-color-buying_team_email);
}

.bu-icon-timeline_crm_activity {
  color: var(--bu-color-crm_activity);
}

.bu-icon-timeline_meeting {
  color: var(--bu-color-meeting);
}

.bu-icon-timeline_next_steps {
  color: var(--bu-color-next_steps);
}

.bu-icon-timeline_selling_team_email {
  color: var(--bu-color-selling_team_email);
}

.bu-icon-logo_datadog {
  color: var(--bu-color-datadog-purple);
}

/** Tooltip */
*[data-bu-tooltip]:not([data-bu-tooltip='']) {
  position: relative;
}

*[data-bu-tooltip]:not([data-bu-tooltip='']):after {
  display: none;
  content: attr(data-bu-tooltip);
  visibility: hidden;

  position: absolute;
  word-break: break-all;
  padding: 3px 12px;
  width: max-content;
  border-radius: 2px;
  background: #4a4a4a;
  color: #fff;
  font-size: 12px;
  line-height: 12px;

  z-index: 1000;
}

*[data-bu-tooltip]:not([data-bu-tooltip='']):hover::after {
  display: block;
  visibility: visible;
  transition: visibility 0s linear 0.5s;
}

*[data-bu-tooltip]:not([data-bu-tooltip='']):after,
*[data-bu-tooltip]:not(
    [data-bu-tooltip='']
  )[data-bu-tooltip-position='bottom center']:after {
  transform: translateX(-50%) translateY(100%);
  left: 50%;
  bottom: -3px;
}

*[data-bu-tooltip]:not(
    [data-bu-tooltip='']
  )[data-bu-tooltip-position='top center']:after {
  transform: translateX(-50%) translateY(-100%);
  left: 50%;
  bottom: initial;
  top: -3px;
}
