.react-calendar {
  padding: 15px;
  background: #fff;
}

.react-calendar__navigation__label {
  border: none;
  background: none;
  font-size: 14px;
  color: var(--bu-gray-900);
  font-family: var(--bu-font-medium);
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.react-calendar__navigation__arrow {
  background: none;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  font-size: 25px;
  height: 30px;
  padding: 0px 3px;
  line-height: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  transition: 0.4s all;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  -ms-transition: 0.4s all;
  -o-transition: 0.4s all;
}

.react-calendar__navigation__arrow:hover {
  border-color: var(--bu-gray-400);
}

.react-calendar__navigation {
  display: flex;
  margin-bottom: 14px;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__arrow:focus {
  outline: none;
}

.react-calendar__month-view__weekdays {
  margin-bottom: 12px;
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
  font-size: 12px;
  font-family: var(--bu-font-regular);
  color: var(--bu-gray-700);
}

.react-calendar__month-view__days {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

abbr[title],
abbr[data-original-title] {
  border-bottom: none !important;
  cursor: default !important;
  text-decoration: none !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  font-size: 0 !important;
  background: transparent !important;
}

.react-calendar__tile {
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding: 6px;
  background: transparent;
  font-family: var(--bu-font-regular);
  font-size: 14px;
  color: var(--bu-gray-700);
}

.react-calendar__tile {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.react-calendar__tile:focus {
  outline: none;
}

.react-calendar__tile:hover {
  background: #ddf0f9;
}

.react-calendar__tile--active.react-calendar__tile--rangeStart {
  background: #30b0d1;
  color: #fff;
}

.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
  background: transparent;
  color: #fff;
}

.react-calendar__tile--active {
  background: #ddf0f9;
  color: #000;
}

.react-calendar-b-disabled {
  background: none !important;
  color: #000 !important;
}
